import React from 'react';
import './ValidationOutcome.css';
import { useNavigate, useParams } from 'react-router-dom';
import ReactJsonView from '@microlink/react-json-view';
import CustomButton from '../CustomButton/CustomButton';
import PhoneFailureImage from '../../assets/images/phone_failure.svg';

interface ValidationOutcomeProps {
  data?: any;
}

function ValidationOutcome({
  data,
}: ValidationOutcomeProps) {
  const navigate = useNavigate();
  const { status } = useParams();

  const success = !(!status || !data || status !== 'success');
  const message = 'The credential has not been able to be validated due to an error during the process';

  return (
    <div>
      {success && (
        <>
          <ReactJsonView
            src={{ data }}
            displayDataTypes={false}
            collapsed={2}
          />
          <CustomButton
            handleClick={() => navigate('/')}
            text="Validate another credential"
            showArrow={false}
          />
        </>
      )}
      {!success && (
      <div className="page-container">
        <section className="page-container__section">
          <h2 className="page-container__section_header">{message}</h2>
          <img
            alt="Failure"
            src={PhoneFailureImage}
            className="page-container__section_image"
          />
          <CustomButton
            handleClick={() => navigate('/')}
            text="Try it again"
            showArrow={false}
          />
        </section>
      </div>
      )}
    </div>

  );
}

ValidationOutcome.defaultProps = {
  data: undefined,
};

export default ValidationOutcome;
