import React, { useEffect, useState } from 'react';
import './CredentialLanding.css';
import { useNavigate } from 'react-router-dom';
import { CredentialUrls } from '../../dtos/credentials';
import CustomButton from '../CustomButton/CustomButton';
import QrCode from '../QrCode/QrCode';
import {
  getPresentationSession,
  initOpenIdPresentation,
} from '../../services/vidcredentials';

// eslint-disable-next-line max-len
const IS_PHONE_REGEX = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

interface InitOpenIdPresentationResponse {
  qrBase64: string;
  rawOpenid4vp: string;
}

function CredentialLanding() {
  const navigate = useNavigate();
  const [isLoaderVisible, setLoaderVisible] = useState(false);
  const [isButtonVisible, setButtonVisible] = useState(true);
  const [isPollingEnabled, setPollingEnabled] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [pollingDataResponse, setPollingDataResponse] = useState('');

  useEffect(() => {
    if (isPollingEnabled) {
      let intervalId: NodeJS.Timer;
      const fetchData = async () => {
        const response = await getPresentationSession();
        if (response.error) {
          clearInterval(intervalId);
          const retryLocation = '';
          navigate('/validation-process/failure', { state: { retryLocation } });
        }
        if (response.data) {
          setPollingDataResponse(response);
          setAuthenticated(true);
          clearInterval(intervalId);
        }
      };
      fetchData()
        .then();
      intervalId = setInterval(fetchData, 5000);
      return () => clearInterval(intervalId);
    }
    return () => {
    };
  }, [isPollingEnabled, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/validation-process/success', { state: { pollingDataResponse } });
    }
  }, [pollingDataResponse, isAuthenticated, navigate]);

  function isMobile() {
    if (IS_PHONE_REGEX.test(navigator.userAgent)) return true;
    return navigator.maxTouchPoints
      && navigator.maxTouchPoints > 2
      && /Macintosh/.test(navigator.userAgent);
  }

  const handleClick = async () => {
    setButtonVisible(false);
    setLoaderVisible(true);
    let scope = '';
    scope = 'VerifiableCredential';
    const retryLocation = CredentialUrls.VALIDATE;
    const response = await initOpenIdPresentation(scope);
    if (!!response && 'error' in response) {
      navigate('/validation-process/failure', { state: { retryLocation } });
      return;
    }
    const initOpenIdPresentationResponse:
    InitOpenIdPresentationResponse = response.data;
    setLoaderVisible(false);
    setPollingEnabled(true);
    if (isMobile()) {
      window.open(initOpenIdPresentationResponse.rawOpenid4vp, '_self');
      return;
    }
    setQrCode(initOpenIdPresentationResponse.qrBase64);
  };

  return (
    <div className="landing-container">
      <h1 className="landing-container__header bold">
        Welcome to the credentials validation portal
      </h1>
      <div
        className="actions-container"
        style={{ justifyContent: 'center' }}
      >
        <div
          className="action"
          key="Check the validity of your credentials"
          style={{ height: qrCode ? 'auto' : '180px' }}
        >
          {(isButtonVisible || isLoaderVisible) && (
          <h2 className="action__header">
            Check the validity of your credentials
            {' '}
          </h2>
          )}
          {!!qrCode && (
          <h2 className="action__header__qr">
            Scan the QR code with your digital wallet to validate the credential
          </h2>
          )}
          {isButtonVisible && (
          <div
            id="action__button-wrapper"
            className="action__button-wrapper"
          >
            <CustomButton
              handleClick={() => handleClick()}
              text="Validate my credential"
              showArrow
            />
          </div>
          )}
          {isLoaderVisible && (
          <div className="action__loader-wrapper">
            <div className="loader" />
          </div>
          )}
          {!!qrCode && (
          <div
            id="action__qrCode-wrapper"
            className="action__qrCode-wrapper"
          >
            <QrCode
              base64img={qrCode}
            />
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CredentialLanding;
