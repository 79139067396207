import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ValidationOutcome from '../../components/ValidationOutcome/ValidationOutcome';

export default function ValidationOutcomeScreen() {
  const { status } = useParams();
  const { state } = useLocation();

  if (state && state.pollingDataResponse && status === 'success') {
    return (
      <ValidationOutcome data={state.pollingDataResponse} />
    );
  }

  if (status === 'failure') {
    return <ValidationOutcome />;
  }

  return null;
}
