import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ValidationOutcomeScreen from './screens/ValidationOutcomeScreen/ValidationOutcomeScreen';
import Home from './screens/home';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/validation-process/:status"
          element={<ValidationOutcomeScreen />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
