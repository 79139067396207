import axios from 'axios';
import BACKEND from '../config';

export interface BackendError {
  statusCode: number;
  internalCode: string;
  message: string;
  timestamp: string;
}

export interface ParsedBackendError {
  title: string;
  details?: string;
}

function translateBackendError(error: BackendError): ParsedBackendError {
  const parsedError = {
    title: 'Internal error',
    details: error.message.split(']')[1],
  };
  return parsedError;
}

async function initOpenIdPresentation(scope: string) {
  try {
    const body = {
      scope,
    };
    const url = `${BACKEND.BASE_URL}/presentations`;
    const response = await axios.post(url, body);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${'error al iniciar presentatción con oidc4vp'} ${response.data}.`);
    }
    localStorage.setItem('presentation_session_id', response.data.sessionId);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const parsedError = translateBackendError(
          error.response?.data as BackendError,
        );
        return { error: parsedError };
      }
      return {
        error:
            { message: error.message, name: error.name, code: error.code },
      };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function getPresentationSession() {
  try {
    const sessionId = localStorage.getItem('presentation_session_id');
    const url = `${BACKEND.BASE_URL}/presentations/${sessionId}`;
    const response = await axios.get(url);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${'error al obtener presentatción'} ${response.data}.`);
    }
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const parsedError = translateBackendError(
          error.response?.data as BackendError,
        );
        return { error: parsedError };
      }
      return {
        error:
            { message: error.message, name: error.name, code: error.code },
      };
    }
    return { error: { title: 'Unknown' } };
  }
}

export {
  initOpenIdPresentation,
  getPresentationSession,
};
